
import { combineReducers } from "redux";
import DataSets from "./data-source";
import Users from "./user";


const createRootReducer = (history: any) =>
  combineReducers({
    DataSets,
    Users
  });

export default createRootReducer;
