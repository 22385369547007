import { Outlet } from "react-router-dom";
import { Sidebar } from "../components/Sidebar";
import { Layout, Space } from "antd";

const { Header, Footer, Sider, Content } = Layout;

export const Main = () => {
  return (
    <Layout style={{ height: "100vh" }}>
      <Sider className="sidebar">
        <Sidebar></Sidebar>
      </Sider>

      <Layout>
        <Header className="header"></Header>
        <Content style={{ padding: "20px 20px" }} className="content">
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
