import { useDispatch } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import createRootReducer from "./reducers";


const createBrowserHistory = require("history").createBrowserHistory;
export const history = createBrowserHistory();
const middlewares = [thunk];

export default function configureStore(preloadedState = {}) {
  return createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        
        ...middlewares
      )
    )
  );
}
export const store = configureStore();

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
