import moment from "moment";
import "moment-timezone";
import { STOCK_DEV_ACTION } from "../actions";

const COLLECTION_NAME = "STOCK_DEV_USERS";

interface IDataSetList {
  user: { name: string } | null;
  requested: boolean;
  loaded: boolean;
}

const initialState: IDataSetList = {
  user: null,
  requested: false,
  loaded: false,
};

export function loadUsers(firebase: any, userId: string) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.DataSets.requested) return;
    dispatch({
      type: STOCK_DEV_ACTION.LOAD_USERS,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    const user = await firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .doc(userId)
      .get();
    dispatch({
      type: STOCK_DEV_ACTION.LOAD_USERS,
      payload: {
        user: user.exists? user.data():null,
        loaded: true,
        requested: true,
      },
    });
  };
}

export default function Users(state = initialState, action: any) {
  switch (action.type) {
    case STOCK_DEV_ACTION.LOAD_USERS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const userSelector = (state: any) => state.Users;
