import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  Routes,
  Route,
  Outlet,
  Link,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { BarChartView } from "./routes/Sentiment";
import { Dashboard } from "./routes/Dashboard";
import { Main } from "./routes/layout";
import configureStore, { useAppDispatch } from "./redux";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Login } from "./login";
import { AuthProvider, useAuth } from "./auth";
import { loadUsers, userSelector } from "./redux/reducers/user";
import { firebaseApp } from "./firebase";
import { Engine } from "./routes/Engine";

function PrivateRoute(props: any) {
  let auth = useAuth();
  const user = useSelector(userSelector);
  const navigate = useNavigate();
  if (auth?.isLoadingUser) return null;
  if (user?.requested && !user?.loaded) return null;
  if (!auth?.isLoadingUser && !auth?.authUser) {
    navigate("/login");
    return;
  }
  if (user && user?.loaded && !user.user) {
    navigate("/login");
    return;
  }
  return props.element;
}
function App() {
  let auth = useAuth();
  const dispatch = useAppDispatch();
  const user = useSelector(userSelector);
  useEffect(() => {
    if (auth?.authUser)
      dispatch(loadUsers(firebaseApp, (auth?.authUser as any).uid));
  }, [auth?.authUser]);
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<PrivateRoute element={<Main />} />}>
        <Route
          path="/sentiment-visualizer"
          element={<PrivateRoute element={<BarChartView />} />}
        />
         <Route
          path="/engine"
          element={<PrivateRoute element={<Engine />} />}
        />
      </Route>
      
    </Routes>
  );
}

export default App;
