import { Card, Col, Row } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { firebaseApp } from "../../firebase";
import { useAppDispatch } from "../../redux";
import {
  dataSetSelector,
  loadDataSets,
} from "../../redux/reducers/data-source";
import { Settings } from "./settings";
import { Table } from "./table";
import { BasketObj } from "./types";

export const Engine = () => {
  const [baskets, setBaskets] = useState<BasketObj[]>([]);
  const dispatch = useAppDispatch();
  const [dataSetUrl, setDataSetUrl] = useState(null);
  const [dataSetName, setDataSetName] = useState("");
  const [stockData, setStockData] = useState<any>();
  useEffect(() => {
    dispatch(loadDataSets(firebaseApp));
  }, []);

  const parseData = (rows: any) => {
    const stockIndex: { [key: number]: string } = {};
    const closeIndex: { [key: string]: any[] } = {};
    for (let i = 1; i < rows[0].length; i++) {
      stockIndex[i] = rows[0][i + 1];
    }

    for (let i = 1; i < rows.length; i++) {
      for (let j = 1; j < rows[i].length; j++) {
        if (!closeIndex[stockIndex[j ]]) {
          closeIndex[stockIndex[j ]] = [];
        }
        closeIndex[stockIndex[j ]].push([rows[i][0], rows[i][j]]);
      }
    }
    
  };
  useEffect(() => {
    if (dataSetUrl) {
      axios
        .get(dataSetUrl, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          parseData(res.data);
        });
    }
  }, [dataSetUrl]);

  const dataSetState = useSelector(dataSetSelector);

  const updateDataSet = () => {
    if (dataSetState?.stocks?.length > 0) {
      setDataSetName(dataSetState?.stocks?.[0].name);
      setDataSetUrl(dataSetState?.stocks[0].url);
    }
  };

  useEffect(() => {
    updateDataSet();
  }, [dataSetState]);

  return (
    <iframe width="100%" height="100%" style={{border:"none"}} src="/stock-test.html"></iframe>
  );
};
