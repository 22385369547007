import ReactEchartsCore from "echarts-for-react/lib/core";

import {
  BrushComponent,
  CalendarComponent,
  DatasetComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  GridComponent,
  GridSimpleComponent,
  LegendComponent,
  MarkLineComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
  VisualMapContinuousComponent,
  VisualMapPiecewiseComponent,
} from "echarts/components";
import { BarChart, HeatmapChart, LineChart } from "echarts/charts";
import readXlsxFile from "read-excel-file";
import { GaugeChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import * as echarts from "echarts/core";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Button, Card, Col, Form, List, Modal, Row, Switch } from "antd";
import { SeriesConfig } from "./series-config";
import { DefaultChart } from "./default-chart";
import { HeatMapChart } from "./heat-map-chart";
import {
  dataSetSelector,
  loadDataSets,
} from "../../redux/reducers/data-source";
import { firebaseApp } from "../../firebase";
import { useSelector } from "react-redux";
import axios from "axios";
import { useAppDispatch } from "../../redux";

export const BarChartView = () => {
  const [rows, setRows] = useState([]);
  const [seriesCount, setSeriesCount] = useState([]);
  const echartRef = useRef(null);
  const [seriesConfig, setSeriesConfig] = useState<any>([]);
  const [showSettings, setShowSettings] = useState(false);
  const [dataSetUrl, setDataSetUrl] = useState(null);
  const [dataSetName, setDataSetName] = useState("");
  const [showDataSetList, setShowDataSetList] = useState(false);
  const [dataSetIndex, setDataSetIndex] = useState(0);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadDataSets(firebaseApp));
  }, []);

  useEffect(() => {
    if (dataSetUrl) {
      axios
        .get(dataSetUrl, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          rowsUpdated(res.data);
        });
    }
  }, [dataSetUrl]);

  const dataSetState = useSelector(dataSetSelector);
  const updateDataSet = () => {
    if (dataSetState?.sentiments?.length > 0) {
      setDataSetName(dataSetState?.sentiments?.[dataSetIndex].name);
      setDataSetUrl(dataSetState?.sentiments[dataSetIndex].url);
    }
  };
  useEffect(() => {
    updateDataSet();
  },[dataSetState]);

  const detectColorFromHeader = (header: string) => {
    switch (header) {
      case "Sentiment":
        return "#1d9afb";
      case "Endeks":
        return "rgb(64, 183, 77)";
    }
    return "#1d9afb";
  };

  const detectVisualMapFromIndex = (header: string) => {
    switch (header) {
      case "Sentiment":
      case "Endeks":
        return undefined;
      case "Momentum":
        return {
          show: false,
          seriesIndex: 0,
          pieces: [
            {
              gt: 0,
              lte: 26,
              color: "#1d9afb",
            },
            {
              gt: -26,
              lte: 0,
              color: "#ff0000",
            },
          ],
        };
    }
  };

  const detectTypeFromHeader = (header: string) => {
    switch (header.trim()) {
      case "Sentiment":
      case "Endeks":
        return "line";
      case "Momentum":
        return "bar";
    }
    return "line";
  };

  const createConfigForRows = (rows: any) => {
    const configs = [];
    for (let i = 1; i < rows[0].length; i++) {
      const visualMap = detectVisualMapFromIndex(rows[0][i]);
      if (visualMap) visualMap.seriesIndex = i - 1;
      const config = {
        type: detectTypeFromHeader(rows[0][i]),
        color: detectColorFromHeader(rows[0][i]),
        visualMap,
      };

      configs.push(config);
    }
    setSeriesConfig(configs);
  };

  const rowsUpdated = (rows: any) => {
    let series = [];
    for (let i = 0; i < rows[0].length - 1; i++) {
      series.push(i);
    }
    createConfigForRows(rows);
    setSeriesCount(series as any);
    setRows(rows as any);
  };

  return (
    <Row gutter={8} style={{ height: "100%" }}>
      <Col span={showSettings ? 20 : 24}>
        <Card
          extra={
            <div style={{ display: "flex", gap: "10px" }}>
              <Button onClick={() => setShowDataSetList(true)}>
                Veri Seti
              </Button>
              <Switch onChange={(v) => setShowSettings(v)} />
            </div>
          }
          title={dataSetName}
          bodyStyle={{ height: "100%" }}
          style={{ height: "90%" }}
        >
          <DefaultChart rows={rows} config={seriesConfig} />
        </Card>
      </Col>
      {showSettings && (
        <Col span={4}>
          <Card>
            <Form layout="horizontal">
              <Form.Item label="Dosya">
                <input
                  type="file"
                  onChange={(e: any) => {
                    readXlsxFile(e.target.files[0]).then((rows) => {
                      rowsUpdated(rows);
                    });
                  }}
                />
              </Form.Item>
              <>
                {seriesCount.map((series, index) => {
                  return (
                    <SeriesConfig
                      defaultConfig={
                        seriesConfig.length > index
                          ? { ...seriesConfig[index] }
                          : undefined
                      }
                      name={rows[0][index + 1]}
                      index={index}
                      onConfigChange={(index: number, config: any) => {
                        seriesConfig[index] = config as any;
                        setSeriesConfig([...seriesConfig]);
                      }}
                    />
                  );
                })}
              </>
            </Form>
          </Card>
        </Col>
      )}
      {showDataSetList && (
        <Modal
        cancelButtonProps={{ style: { display: 'none' } }}
          title="Veri Setleri"
          open={showDataSetList}
          onOk={() => setShowDataSetList(false)}
        ><List bordered>
          {dataSetState?.sentiments?.map((dataSet: any, index: number) => {
            return (
              
                <List.Item
                style={{cursor:"pointer"}}
                  onClick={() => {
                    setDataSetIndex(index);
                    updateDataSet();
                    setShowDataSetList(false);
                  }}
                >
                  {dataSet.name}
                </List.Item>
            
            );
          })}
            </List>
        </Modal>
      )}
    </Row>
  );
};
