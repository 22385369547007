import { useState } from "react";
import { auth } from "../firebase";


export const useAuthUser = () => {
  const [authUser, setAuthUser] = useState<object | null>(null);
  const [error, setError] = useState("");
  const [isLoadingUser, setLoadingUser] = useState(true);
  const [isLoading, setLoading] = useState(false);
  
  
  auth.onAuthStateChanged(function (user) {
    setLoadingUser(false);
    setAuthUser(user);
  });

  const userLogin = (user: { email: string; password: string },onError?:(error:any)=>void) => {
    auth.signInWithEmailAndPassword(user.email, user.password).then((user) => {
      setAuthUser(user);
    }).catch(err=>{
      if(onError)
        onError(err)
    });
  };

  const renderSocialMediaLogin = () => null;

  const userSignOut = () => {
    auth.signOut();
  };

  const getAuthUser = () => {
    return auth?.currentUser;
  };

  // Return the user object and auth methods
  return {
    isLoadingUser,
    isLoading,
    authUser,
    error,
    setError,
    setAuthUser,
    getAuthUser,
    userLogin,
    userSignOut,
    renderSocialMediaLogin,
  };
};
