import { Divider, Form, Switch } from "antd";
import { useEffect, useState } from "react";
import { ChromePicker, SketchPicker, TwitterPicker } from "react-color";
import { ColorPicker } from "../../components/ColorPicker/ColorPicker";

const CHART_TYPES = {
  line: "Line",
  bar: "Bar",
  area: "Area",
};

export const SeriesConfig = (props: any) => {
  const [type, setType] = useState(props.defaultConfig?.type ?? "line");
  const [visualMap, setVisualMap] = useState<any>(props.defaultConfig?.visualMap);
  const [color, setColor] = useState(props.defaultConfig?.color ?? "red");
  const [visualMapColor1, setVisualMapColor1] = useState(
    props.defaultConfig?.visualMap?.pieces?.[0].color ?? "#1d9afb"
  );
  const [visualMapColor2, setVisualMapColor2] = useState(
    props.defaultConfig?.visualMap?.pieces?.[1].color ?? "#ff0000"
  );

  const createConfig = () => {
    const config = {
      visualMap,
      type,
      color,
    };

    return config;
  };
  const updateVisualMap = () => {
    setVisualMap({
      seriesIndex: props.index,
      show: false,
      pieces: [
        {
          gt: 0,
          lte: 26,
          color: visualMapColor1,
        },
        {
          gt: -26,
          lte: 0,
          color: visualMapColor2,
        },
      ],
    });
  };
  useEffect(() => {
    props.onConfigChange(props.index, createConfig());
  }, [type, visualMap, color, visualMapColor1, visualMapColor2]);

  return (
    <>
      <label>{props.name}</label>
      <Form.Item label="Grafik Türü">
        <select
          value={type}
          onChange={(e) => {
            setType(e.target.value);
          }}
        >
          {Object.keys(CHART_TYPES).map((chartType: any) => {
            return (
              <option selected={chartType == type} value={chartType}>
                {(CHART_TYPES as Record<string, string>)[chartType]}
              </option>
            );
          })}
        </select>
      </Form.Item>
      {type != "heatmap" && (
        <Form.Item label="Görsel Haritası">
          <Switch
          checked={!!visualMap}
            onChange={(e) => {
              if (e) {
                updateVisualMap();
              } else setVisualMap(undefined);
            }}
          ></Switch>
        </Form.Item>
      )}
      {!visualMap && (
        <Form.Item label="Renk">
          <ColorPicker
            color={color}
            onColorChange={(color: any) => setColor(color)}
          ></ColorPicker>
        </Form.Item>
      )}
      {visualMap && (
        <>
          <Form.Item label="Renk 1">
            <ColorPicker
              color={visualMapColor1}
              onColorChange={(color: any) => {
                setVisualMapColor1(color);
                updateVisualMap();
              }}
            ></ColorPicker>
          </Form.Item>
          <Form.Item label="Renk 2">
            <ColorPicker
              color={visualMapColor2}
              onColorChange={(color: any) => {
                setVisualMapColor2(color);
                updateVisualMap();
              }}
            ></ColorPicker>
          </Form.Item>
        </>
      )}
      <Divider />
    </>
  );
};
