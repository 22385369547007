import { useState } from "react";
import { SketchPicker } from "react-color";

export const ColorPicker = (props: any) => {
  const [color, setColor] = useState(props.color ?? "red");
  const [showColor, setShowColor] = useState(false);

  return (
    <div>
      <div
        style={{
          padding: "5px",
          background: "#fff",
          borderRadius: "1px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          display: "inline-block",
          cursor: "pointer",
        }}
        onClick={() => setShowColor(true)}
      >
        <div
          style={{
            width: "36px",
            height: "14px",
            borderRadius: "2px",
            background: props.color,
          }}
        />
      </div>
      {showColor ? (
        <div style={{ position: "absolute", zIndex: "2" }}>
          <div
            style={{
              position: "fixed",
              top: "0px",
              right: "0px",
              bottom: "0px",
              left: "0px",
            }}
            onClick={() => setShowColor(false)}
          />
          <SketchPicker
            color={color}
            onChange={(c) => {
              setColor(c.hex);
              props.onColorChange(c.hex);
            }}
          />
        </div>
      ) : null}
    </div>
  );
};
