import ReactEchartsCore from "echarts-for-react/lib/core";

import {
  BrushComponent,
  CalendarComponent,
  DatasetComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  GridComponent,
  GridSimpleComponent,
  LegendComponent,
  MarkLineComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
  VisualMapContinuousComponent,
  VisualMapPiecewiseComponent,
} from "echarts/components";
import { BarChart, HeatmapChart, LineChart } from "echarts/charts";
import readXlsxFile from "read-excel-file";
import { GaugeChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import * as echarts from "echarts/core";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Card, Col, Form, Modal, Row } from "antd";
import { SeriesConfig } from "./series-config";
export const DefaultChart = (props: any) => {
  echarts.use([
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    DataZoomComponent,
    DatasetComponent,
    DataZoomSliderComponent,
    GridSimpleComponent,
    DataZoomInsideComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
    BrushComponent,
    LegendComponent,
    MarkLineComponent,
    LineChart,
    CanvasRenderer,
    GaugeChart,
    BarChart,
    HeatmapChart,
    CalendarComponent,
  ]);
  const [series, setSeries] = useState<any[]>([]);
  const [yAxis, setYAxis] = useState<any[]>([]);
  const echartRef = useRef(null);
  const [visualMap, setVisualMap] = useState([] as any);

  const createSeries = () => {
    if (!props.rows || props.rows.length == 0 || !props.config) return;

    let seriesList = [];
    for (let i = 0; i < props.config.length; i++) {
      const serie = {
        name: props.rows[0][i + 1],
        type: props.config[i].type == "area" ? "line" : props.config[i].type,
        xAxisIndex: 0,
        yAxisIndex: props.config.length == 2 ? i : i <= 1 ? 0 : 1,
        showSymbol: false,

        areaStyle: props.config[i].type == "area" ? {} : undefined,
        itemStyle: {
          normal: {
            color: props.config[i].color ?? (i == 0 ? "#1d9afb" : "#40B74D"), // this will be color of uncontrolled item
          },
        },

        data: props.rows.slice(1).map((row: any) => {
          return {
            visualMap: !!props.config[i].visualMap,

            value: [moment(row[0].toString()).format("DD.MM.YYYY"), row[i + 1]],
          };
        }),
      };
      seriesList.push(serie);
    }
    setSeries(seriesList);
  };
  useEffect(() => {
    createSeries();
    createYAxis();
  }, [props.rows, props.config]);
  const createYAxis = () => {
    let axis = [];
    for (let i = 0; i < props.config.length; i++) {
      if (i > 0 && i < props.config.length - 1) continue;
      const newAxis = {
        type: "value",
        min: "dataMin",
        max: "dataMax",
        axisLabel: {
          color: "#222",
          formatter: function (value: any) {
            return value.toFixed(2);
          },
        },

        splitLine: {
          show: true,
          lineStyle: {
            color: "#ccc",
          },
        },
      } as any;

      newAxis.boundaryGap = [0, "100%"];

      axis.push(newAxis);
    }
    setYAxis(axis);
  };
  const getDefaultoption = () => {
    return {
      noMerge: true,
      grid: [
        {
          left: "5%",
          right: "5%",
          height: "80%",
        },
      ],

      xAxis: [
        {
          type: "category",
          axisLabel: {
            color: "#222",
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#ccc",
            },
          },
        },
      ],
      toolbox: {
        left: "right",
        itemSize: 16,
        feature: {
          dataView: {},
          brush: {
            title: {
              rect: "Kutu Seçim",
              polygon: "Serbest Seçim",
              lineX: "Yatay Seçim",
              lineY: "Dikey Seçim",
              keep: "Seçimi Koru",
              clear: "Temizle",
            },
            toolbox: ["rect", "polygon", "lineX", "lineY", "keep", "clear"],
          },
          saveAsImage: { title: "Resim Olarak Kaydet" },
        },
      },
      brush: {
        xAxisIndex: "all",
        brushLink: "all",

        outOfBrush: {
          colorAlpha: 0.3,
        },
      },

      yAxis: yAxis,
      dataZoom: [
        {
          xAxisIndex: [0],
          startValue: "2020-06-01",
        },
        {
          type: "inside",
        },
      ],
      legend: {
        icon: "circle",

        textStyle: {
          color: "#333",
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
        },
      },
      series: series,
    } as any;
  };
  const getOption = () => {
    const option = getDefaultoption();
    for (let i = 0; i < props.config.length; i++) {
      if (props.config[i].visualMap){
        if(!option.visualMap){
          option.visualMap=[];
        }
         option.visualMap.push(props.config[i].visualMap);
      }
    }

    const echartCurrent: any = echartRef.current;
    if (echartCurrent) {
      echartCurrent.getEchartsInstance().clear();
      echartCurrent.getEchartsInstance().setOption(option, false);
      echartCurrent.getEchartsInstance().resize();
    }
    return option;
  };
  if (props.config?.length == 0) return null;
  if (props.config?.length >= 2 && yAxis.length < 2) return null;

  return (
    <ReactEchartsCore
      ref={echartRef}
      echarts={echarts}
      style={{
        height: "100%",
        marginBottom: 30,
        backgroundColor: "#transparent",
      }}
      option={getOption()}
    />
  );
};
