import { BarChartOutlined, DeploymentUnitOutlined, LogoutOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useAuth } from "../../auth";
export const Sidebar = () => {
  let auth = useAuth();

  return (
    <div>
      <div className="sidebar-logo-container">
        <img src="/sidebar-logo.png" height="32" />
      </div>
      <ul>
        <li>
          <Link to="/sentiment-visualizer">
            <BarChartOutlined />
            Sentiment/Endeks
          </Link>
        </li>
        <li>
          <Link to="/engine">
          <DeploymentUnitOutlined />
            Engine
          </Link>
        </li>
        <li>
          <a
            href="#"
            onClick={() => {
              auth?.userSignOut();
            }}
          >
            <LogoutOutlined />
            Çıkış
          </a>
        </li>
      </ul>
    </div>
  );
};
