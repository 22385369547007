import { useNavigate } from "react-router-dom";
import { createContext, useContext, useMemo } from "react";
import { useAuthUser } from "./hooks/useAuthUser";
const AuthContext = createContext<ReturnType<typeof useAuthUser> | null>(null);

export const AuthProvider = ({ children }: any) => {
  const auth = useAuthUser();
  

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
