import { Button, Card, Col, Form, Input, Row } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./auth";
import { LockOutlined, UserOutlined } from '@ant-design/icons';

export const Login = () => {
  let auth = useAuth();
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  useEffect(() => {
    if (auth?.authUser) navigate("/");
  }, [auth?.authUser]);
  return (
    <div>
      <Row style={{
            height: "100vh",
            display: "flex",
            alignItems: "center"}
      }>
        <Col md={{span:20, offset:2}} lg={{span:6, offset:9}} xs={{span:20, offset:2}} sm={{span:20,offset:2}} >
          <Card title="Sentiment Global">

            <Form className="login-form">
              <Form.Item
                name="Kullanıcı Adı"
                rules={[{ required: true, message: "Kullanıcı gerekli" }]}
              >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Kullanıcı Adı" onChange={(e) => setUserName(e.target.value)}></Input>
              </Form.Item>
              <Form.Item
                name="Parola"
                rules={[{ required: true, message: "Parola gerekli" }]}
              >
                <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Parola" onChange={(e) => setPassword(e.target.value)} />
              </Form.Item>
              <Form>
                <Button
                className="login-form-button"
                  type="primary"
                  onClick={() => auth?.userLogin({ email: userName, password })}
                >
                  Giriş
                </Button>
              </Form>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
