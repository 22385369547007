import moment from "moment";
import "moment-timezone";
import { STOCK_DEV_ACTION } from "../actions";

const DATA_COUNT = 10;
const COLLECTION_NAME = "STOCK_DEV_DATA_SET";

interface IDataSetList {
  dataSets: { name: string; url: string }[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IDataSetList = {
  dataSets: [],
  requested: false,
  loaded: false,
};

export function loadDataSets(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.DataSets.requested) return;
    dispatch({
      type: STOCK_DEV_ACTION.LOAD_DATA_SETS,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        let dataSets: {type:string,url:string,name:string,version:string}[] = [];
        querySnapshot.forEach(function (doc: any) {
            dataSets.push({
            name: doc.data()["name"],
            type: doc.data()["type"],
            url: doc.data()["url"],
            version: doc.data()["version"],
          });
        });

        dispatch({
          type: STOCK_DEV_ACTION.LOAD_DATA_SETS,
          payload: {
            sentiments: dataSets.filter(dataSet=>dataSet.type=="sentiment"),
            stocks: dataSets.filter(dataSet=>dataSet.type=="stock"),
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function DataSets(state = initialState, action: any) {
  switch (action.type) {
    case STOCK_DEV_ACTION.LOAD_DATA_SETS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const dataSetSelector = (state: any) => state.DataSets;
