import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

export const firebaseConfig = {
  apiKey: "AIzaSyB_DadiPKlPLIt3sw_uPFr6i3KxI1WDiIs",
  authDomain: "algo-senti.firebaseapp.com",
  databaseURL: "https://algo-senti.firebaseio.com",
  projectId: "algo-senti",
  storageBucket: "algo-senti.appspot.com",
  messagingSenderId: "109562175983",
  appId: "1:109562175983:web:66ed44b4c1feae44dbd61a",
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const fireStore = firebaseApp.firestore();
export const auth = firebaseApp.auth();
export const storage = firebaseApp.storage()